import { Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';
import { Invoice } from 'types/invoices';

const Flex = styled('div')({
  display: 'flex',
  backgroundColor: '#fff',
  gap: 5,
  marginBottom: 10,
  padding: '0 15px',
  paddingBottom: 10,
  justifyContent: 'space-between',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface InvoicesTotalsProps {
  invoices: Invoice[];
}

const InvoicesTotals: React.FC<InvoicesTotalsProps> = ({ invoices }) => {
  return (
    <Flex>
      <Column>
        <Typography variant="caption">Quantidade de registros:</Typography>
        <Typography variant="caption" fontWeight={600}>
          {invoices.length}
        </Typography>
      </Column>

      <Column>
        <Typography variant="caption">Total:</Typography>
        <Typography variant="caption" fontWeight={600}>
          {moneyFormat(invoices.reduce((sum, item) => sum + item.valor, 0))}
        </Typography>
      </Column>

      <Column>
        <Typography variant="caption">Pago:</Typography>
        <Typography variant="caption" fontWeight={600}>
          {moneyFormat(invoices.reduce((sum, item) => sum + item.valor_pago, 0))}
        </Typography>
      </Column>

      <Column>
        <Typography variant="caption">Falta:</Typography>
        <Typography variant="caption" fontWeight={600}>
          {moneyFormat(invoices.reduce((sum, item) => sum + item.valor_falta, 0))}
        </Typography>
      </Column>
    </Flex>
  );
};

export default InvoicesTotals;
