import React, { useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeader from 'components/page-header/PageHeader';
import AnalysisProductFilterBox from './filter/AnalysisProductFilterBox';
import FilterMorePage from 'components/filter-more/FilterMore';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFetchAnalysisProduct } from './hooks/useFetchAnalysisProduct';
import AnalysisProductActions from './action/AnalysisProductActions';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import AbcProductListTable from 'pages/reports/commercial/abc/compared/product/list/table/AbcProductListTable';

const AnalysisProduct: React.FC = () => {
  const [dialogDate, setDialogDate] = useState(false);

  const {
    products,
    handleSearch,
    searchText,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    handleChangeFilter,
    filter,
  } = useFetchAnalysisProduct();
  const [displayMode] = useDisplayMode();

  function handleCloseDialogDesktop() {
    setDialogDate(false);

    handleSearch(searchText);
  }

  return (
    <>
      <Appbar
        title="Análise de produto"
        ActionsComponent={<AnalysisProductActions handleOpenDialog={() => setDialogDate(true)} />}
      />
      <PageHeader title="Análise de produto" />

      {dialogDate && (
        <FilterMorePage
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          onExited={() => setDialogDate(false)}
          handleChangeFilter={handleChangeFilter}
        />
      )}

      <AnalysisProductFilterBox
        filter={filter}
        disabled={products.length === 0}
        handleOpenDialog={() => setDialogDate(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : products.length === 0 ? (
        <NoData message="Análise não disponível" />
      ) : (
        <>
          <div style={{ display: 'grid', gap: 10 }}>
            <PaginationProvider>
              <AbcProductListTable products={products} />
              <Pagination count={products.length} />
            </PaginationProvider>
          </div>
        </>
      )}
    </>
  );
};

export default AnalysisProduct;
