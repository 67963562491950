import React, { useEffect, useState } from 'react';
import { AgroupedSegmentItemEntry } from '../hooks/useFetchItemEntryDashboard';
import { styled, Typography } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import useTableOrder from 'hooks/tableOrder';

interface ItemEntryDashboardSegmentProps {
  segments: AgroupedSegmentItemEntry[];
}

const Container = styled('div')({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
});

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#eeeff8',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '1fr 100px',
  '& .segment-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const ItemEntryDashboardSegment: React.FC<ItemEntryDashboardSegmentProps> = ({ segments }) => {
  const { rowsPerPage, page } = usePagination();
  const [filtered, setFiltered] = useState<AgroupedSegmentItemEntry[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(segments);
  }, [segments]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por segmento</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('segmento')} variant="caption" fontWeight={600}>
              Segmento
            </Typography>
            <Typography onClick={() => handleSort('custo_total')} align="right" variant="caption" fontWeight={600}>
              Custo Total
            </Typography>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((segment, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{segment.segmento}</Typography>
              <Typography align="right" variant="caption">
                {segment.formattedTotal}
              </Typography>
            </Row>
          ))}
        </List>
        <Pagination count={segments.length} />
      </Content>
    </Container>
  );
};

export default ItemEntryDashboardSegment;
