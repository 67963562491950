import React, { MouseEvent } from 'react';
import { Typography, styled, IconButton, ListItemButton } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { NoteEntry } from 'types/noteEntry';

const ListItemStyled = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '100px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type NoteEntryItemModuleProps = {
  note: NoteEntry;
  handleClick(note: NoteEntry): void;
};

const NoteEntryItemModule: React.FC<NoteEntryItemModuleProps> = ({ note, handleClick }) => {
  function openExternalSite(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    navigator.clipboard.writeText(note.chave);
    window.open(`https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx`, '_blank');
  }

  return (
    <ListItemStyled onClick={() => handleClick(note)}>
      <Grid className="bottom">
        <Typography variant="caption">Filial:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.filial}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">CNPJ:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.cnpj}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Fornecedor:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.fornecedor}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Data Entrada:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.data_entrada}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">NF:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.nf}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor Total:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.formattedTotal}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor Desconto:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.formattedDiscount}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor IPI:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {note.formattedIpi}
        </Typography>
      </Grid>

      <IconButton style={{ justifyContent: 'end', width: '100%' }} size="small" onClick={e => openExternalSite(e)}>
        <OpenInNew />
      </IconButton>
    </ListItemStyled>
  );
};

export default NoteEntryItemModule;
