import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import SelectBranch from 'components/select-branches/SelectBranch';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';
import FilterMoreForm from 'components/filter-more/FilterMoreForm';
import InsideLoading from 'components/loading/InsideLoading';
import { AbcCustomerWithoutSaleFilterParams } from '../customer-without-sale/CustomerWithoutSale';
import { Button, MenuItem, Select, Typography, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

const Form = styled('div')({
  display: 'flex',
  gap: 20,
  flexDirection: 'column',
  justifyContent: 'space-between',
});

type AbcCustomerDateFilterProps = {
  onExited(): void;
  onSearch(): void;
  filter: AbcCustomerWithoutSaleFilterParams;
  handleChangeFilter(index: keyof AbcCustomerWithoutSaleFilterParams, value: any): void;
};

const AbcCustomerDateFilter: React.FC<AbcCustomerDateFilterProps> = ({
  onExited,
  onSearch,
  filter,
  handleChangeFilter,
}) => {
  const { units, segments, categories, subCategories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <>
        {loading ? (
          <InsideLoading />
        ) : (
          <Form>
            <FilterMoreForm
              manufacturers={manufacturers}
              categories={categories}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              segments={segments}
              subCategories={subCategories}
              subGroups={subGroups}
              units={units}
            />

            <Select
              fullWidth
              label="tipo de venda"
              style={{ height: '100%' }}
              value={filter.typeSale}
              onChange={e => handleChangeFilter('typeSale', e.target.value)}
            >
              <MenuItem value="T">Todas</MenuItem>
              <MenuItem value="C">Coligadas</MenuItem>
              <MenuItem value="V">Vendas</MenuItem>
            </Select>

            <SelectBranch
              branch_id={filter.selectedBranchId}
              handleChange={e => handleChangeFilter('selectedBranchId', e)}
            />

            <KeyboardDatePicker
              label="Com venda - Data inicial"
              value={filter.sale_initialDate}
              onChange={date => handleChangeFilter('sale_initialDate', date)}
            />

            <KeyboardDatePicker
              label="Com venda - Data final"
              value={filter.sale_finalDate}
              onChange={date => handleChangeFilter('sale_finalDate', date)}
            />

            <KeyboardDatePicker
              label="Sem venda - Data inicial"
              value={filter.withoutSale_initialDate}
              onChange={date => handleChangeFilter('withoutSale_initialDate', date)}
            />

            <KeyboardDatePicker
              label="Sem venda - Data final"
              value={filter.withoutSale_finalDate}
              onChange={date => handleChangeFilter('withoutSale_finalDate', date)}
            />
            <Button type="submit" color="primary" variant="contained" onClick={onSearch}>
              <Typography style={{ marginRight: 5 }} color="inherit">
                Pesquisar
              </Typography>
              <Search fontSize="small" />
            </Button>
          </Form>
        )}
      </>
    </DialogInput>
  );
};

export default AbcCustomerDateFilter;
