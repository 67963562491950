import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import ReportsTab from './ReportsTab';
import ConstructionPage from 'components/construction/ConstructionPage';
import CurveAbcCustomer from './commercial/abc/customer/CurveAbcCustomer';
import CurveAbcProduct from './commercial/abc/product/CurveAbc';
import CurveAbcProductCompared from './commercial/abc/compared/product/CurveAbc';
import ExportEmail from './commercial/export-email/ExportEmail';
import CustomerWithoutSale from './commercial/abc/customer-without-sale/CustomerWithoutSale';
import SalesAppReport from './sales/SalesReport';
import SalesReport from './orders-list/SalesReport';
import MonthlyComparison from './monthly-comparison/MonthlyComparison';
import StockIndicator from './stock/stock-indicator/StockIndicator';
import StockBalance from './stock/stock-balance/StockBalance';
import ReprintSale from 'pages/reprint-sale/ReprintSale';
import Sellout from './sell-out/Sellout';
import CurveAbcManufacturer from './curve-abc/manufacturer/CurveAbcManufacturer';
import CurveAbcSegment from './curve-abc/segment/CurveAbcSegment';
import CurveAbcUnity from './curve-abc/unity/CurveAbcUnity';
import SellerDashboard from './commercial/seller-dashboard/SellerDashboard';
import { useParams } from 'react-router-dom';
import history from 'services/history';
import { useApp } from 'hooks/app';
import { ReportProvider } from './hooks/useReport';
import ReportHome from './home/ReportHome';
import { styled, Typography } from '@mui/material';
import NoteEntryPage from './note-entry/NoteEntry';
import ItemEntryPage from './item-entry/ItemEntry';

const Space = styled('div')(({ theme }) => ({
  marginTop: 48,
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

const ReportsPage: React.FC = () => {
  const { windowWidth } = useApp();
  const { param } = useParams<{ param: string }>();
  const [tab, setTab] = useState(windowWidth < 600 ? 'home' : param || 'resume');

  useEffect(() => {
    if (!param) {
      setTab('construction-page');
      return;
    }

    setTab(param);

    history.push(`/reports/${param}`);
  }, [param, windowWidth]);

  useEffect(() => {
    if (windowWidth >= 600 && tab === 'home') {
      setTab('resume');
      history.push(`/reports/resume`);
    }
  }, [windowWidth, tab]);

  function handleBack() {
    setTab('home');
    history.push('/financial/home');
  }

  return (
    <ReportProvider value={{ handleBack }}>
      <Appbar title="Relatórios" />
      {windowWidth >= 600 && <ReportsTab handleChange={tab => history.push(`/reports/${tab}`)} tab={tab} />}
      <Space />
      {tab === 'stock_balance' ? (
        <StockBalance />
      ) : tab === 'stock_indicator' ? (
        <StockIndicator />
      ) : tab === 'sales_app' ? (
        <SalesAppReport />
      ) : tab === 'sales_compared-product' ? (
        <CurveAbcProductCompared />
      ) : tab === 'sales_monthly-comparison' ? (
        <MonthlyComparison />
      ) : tab === 'sales_report' ? (
        <SalesReport />
      ) : tab === 'sales_reprint-sale' ? (
        <ReprintSale />
      ) : tab === 'curve_abc-product' ? (
        <CurveAbcProduct />
      ) : tab === 'curve_abc-customer' ? (
        <CurveAbcCustomer />
      ) : tab === 'commercial_customer-without-sale' ? (
        <CustomerWithoutSale />
      ) : tab === 'commercial_export-email' ? (
        <ExportEmail />
      ) : tab === 'commercial_sellout' ? (
        <Sellout />
      ) : tab === 'commercial_seller_dashboard' ? (
        <SellerDashboard />
      ) : tab === 'curve_abc-manufacturer' ? (
        <CurveAbcManufacturer />
      ) : tab === 'curve_abc-segment' ? (
        <CurveAbcSegment />
      ) : tab === 'curve_abc-unity' ? (
        <CurveAbcUnity />
      ) : tab === 'home' ? (
        <ReportHome handleChange={tab => history.push(`/reports/${tab}`)} />
      ) : tab === 'item_entry' ? (
        <ItemEntryPage />
      ) : tab === 'note_entry' ? (
        <NoteEntryPage />
      ) : tab === 'resume' ? (
        <Typography variant="h6">Selecione um relatório para visualizar no menu acima</Typography>
      ) : (
        <ConstructionPage />
      )}
    </ReportProvider>
  );
};

export default ReportsPage;
