import React from 'react';
import { styled } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import { useCommercialBi } from '../hooks/useCommercialBi';
import TableLoading from 'components/loading/TableLoading';

interface BlockProps {
  backgroundColor?: string;
}

const Col = styled('div')(props => ({
  display: 'flex',
  flex: 1,
  [props.theme.breakpoints.down('sm')]: {
    display: 'grid',
  },
}));

const Row = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 10,
}));

const Header = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  columnGap: 10,
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  background: '#f5f5f5',
  flex: 1,
  rowGap: 15,
});

const Block = styled('div')<BlockProps>(props => ({
  borderTop: '1px solid #eee',
  borderBottom: '1px solid #eee',
  borderRight: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  '& .title': {
    background: props.backgroundColor ?? 'transparent',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '20px 20px 8px 20px',
  },
  '& .main': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
  '& .percent': {
    fontWeight: 500,
    fontSize: 12,
  },
}));

const CommercialBiFinancial: React.FC = () => {
  const { paymentMethods, loading } = useCommercialBi();

  return (
    <Container>
      <Header>
        <Col>
          {paymentMethods.slice(0, 2).map(item => (
            <Block key={item.forma}>
              <span className="title">{item.forma}</span>
              <div className="main">
                <span className="value">{item.formattedValue}</span>
                <span className="value percent">{item.formattedPercentual}</span>
                <LineAxis color="secondary" />
              </div>
            </Block>
          ))}
        </Col>

        <Col>
          {paymentMethods.slice(2, 4).map(item => (
            <Block key={item.forma}>
              <span className="title">{item.forma}</span>
              <div className="main">
                <span className="value">{item.formattedValue}</span>
                <span className="value percent">{item.formattedPercentual}</span>
                <LineAxis color="secondary" />
              </div>
            </Block>
          ))}
        </Col>

        <Col>
          {paymentMethods.slice(4, 6).map(item => (
            <Block key={item.forma}>
              <span className="title">{item.forma}</span>
              <div className="main">
                <span className="value">{item.formattedValue}</span>
                <span className="value percent">{item.formattedPercentual}</span>
                <LineAxis color="secondary" />
              </div>
            </Block>
          ))}
        </Col>
      </Header>

      <Row>
        {loading ? (
          <>
            <TableLoading />
            <TableLoading />
            <TableLoading />
          </>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
};

export default CommercialBiFinancial;
