import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { NoteEntry } from 'types/noteEntry';

export interface AgroupedBranchesNotesEntry {
  filial: string;
  valor_total: number;
  formattedTotal: string;
}

export interface AgroupedSuppliersNotesEntry {
  fornecedor: string;
  valor_total: number;
  percentual: number;
  formattedPercentual: string;
  formattedTotal: string;
}

export interface AgroupedCfopsNotesEntry {
  cfop: string;
  valor_total: number;
  percentual: number;
  formattedPercentual: string;
  formattedTotal: string;
}

export function useFetchNoteEntryDashboard(notes: NoteEntry[]) {
  function groupByFilial(data: any[]): AgroupedBranchesNotesEntry[] {
    const filialMap = new Map();

    data.forEach(item => {
      if (!filialMap.has(item.id_filial)) {
        filialMap.set(item.id_filial, { filial: item.filial, valor_total: 0 });
      }
      const entry = filialMap.get(item.id_filial);
      entry.valor_total += item.valor_total;
      entry.formattedTotal = moneyFormat(entry.valor_total);
    });

    return Array.from(filialMap.values());
  }

  function groupByCnpj(data: any[]): AgroupedSuppliersNotesEntry[] {
    const cnpjMap = new Map();

    data.forEach(item => {
      if (!cnpjMap.has(item.cnpj)) {
        cnpjMap.set(item.cnpj, { fornecedor: item.fornecedor, valor_total: 0, percentual: 0 });
      }
      const entry = cnpjMap.get(item.cnpj);
      entry.valor_total += item.valor_total;
      entry.percentual += (item.valor_total / data.reduce((acc, curr) => acc + curr.valor_total, 0)) * 100;
      entry.formattedTotal = moneyFormat(entry.valor_total);
      entry.formattedPercentual = percentFormat(entry.percentual);
    });

    return Array.from(cnpjMap.values());
  }

  function groupByCfop(data: any[]): AgroupedCfopsNotesEntry[] {
    const cfopMap = new Map();

    data.forEach(item => {
      if (!cfopMap.has(item.cfop)) {
        cfopMap.set(item.cfop, { cfop: item.cfop, valor_total: 0, percentual: 0 });
      }
      const entry = cfopMap.get(item.cfop);
      entry.valor_total += item.valor_total;
      entry.percentual += (item.valor_total / data.reduce((acc, curr) => acc + curr.valor_total, 0)) * 100;
      entry.formattedTotal = moneyFormat(entry.valor_total);
      entry.formattedPercentual = percentFormat(entry.percentual);
    });

    return Array.from(cfopMap.values());
  }

  const groupedBranches = groupByFilial(notes).sort((a, b) => b.valor_total - a.valor_total);
  const groupedSuppliers = groupByCnpj(notes).sort((a, b) => b.valor_total - a.valor_total);
  const groupedCfops = groupByCfop(notes).sort((a, b) => b.valor_total - a.valor_total);

  return {
    groupedBranches,
    groupedSuppliers,
    groupedCfops,
  };
}
