import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import Login from 'pages/login/Login';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from 'pages/dashboard/commercial/Dashboard';
import Forgot from 'pages/forgot/Forgot';
import PasswordReset from 'pages/passoword-reset/PasswordReset';
import Account from 'pages/account/Account';
import Error404 from 'pages/error/Error404';
import Users from 'pages/users/Users';
import NewUser from 'pages/users/new/User';
import EditUser from 'pages/users/edit/User';
import Profiles from 'pages/profiles/Profiles';
import NewProfile from 'pages/profiles/new/Profile';
import EditProfile from 'pages/profiles/edit/Profile';
import Home from 'pages/home/Home';
import Settings from 'pages/settings/Settings';
import Products from 'pages/registrations/products/Products';
import Product from 'pages/registrations/products/edit/Product';
import UserProfiles from 'pages/user-profiles/Profiles';
import EditUserProfile from 'pages/user-profiles/edit/Profile';
import NewUserProfile from 'pages/user-profiles/new/Profile';
import AccessRules from 'pages/access-rules/AccessRules';
import EditAccessRule from 'pages/access-rules/registration/edit/AccessRule';
import NewAccessRule from 'pages/access-rules/registration/new/AccessRule';
import EmailTemplates from 'pages/email-templates/EmailTemplates';
import EmailTemplateNew from 'pages/email-templates/registration/new/EmailTemplateNew';
import EmailTemplateUpdate from 'pages/email-templates/registration/update/EmailTemplateUpdate';
import AppBanners from 'pages/app-banners/AppBanners';
import AppBannerNew from 'pages/app-banners/registration/new/AppBannerNew';
import AppBannerUpdate from 'pages/app-banners/registration/update/AppBannerUpdate';
import SliderBanners from 'pages/slider-banners/SliderBanners';
import SiteBannerNew from 'pages/slider-banners/registration/new/SiteBannerNew';
import SiteBannerUpdate from 'pages/slider-banners/registration/update/SiteBannerUpdate';
import NewsletterSubscribers from 'pages/newsletters/NewsletterSubscribers';
import Partners from 'pages/partners/Partners';
import PartnerNew from 'pages/partners/registration/new/PartnerNew';
import PartnerUpdate from 'pages/partners/registration/update/PartnerUpdate';
import Branches from 'pages/branches/Branches';
import BranchUpdate from 'pages/branches/registration/update/BranchUpdate';
import BranchNew from 'pages/branches/registration/new/BranchNew';
import SiteSettings from 'pages/site-settings/SiteSettings';
import Courses from 'pages/courses/Courses';
import CourseUpdate from 'pages/courses/registration/update/PartnerUpdate';
import CourseNew from 'pages/courses/registration/new/CourseNew';
import Resellers from 'pages/resellers/Resellers';
import Banners from 'pages/banners/Banners';
import BannerNew from 'pages/banners/registration/new/BannerNew';
import BannerUpdate from 'pages/banners/registration/update/BannerUpdate';
import Pages from 'pages/site-pages/Pages';
import PageNew from 'pages/site-pages/registration/new/PageNew';
import PageUpdate from 'pages/site-pages/registration/update/PageUpdate';
import SiteH2iCustomers from 'pages/site-h2i/customers/Customers';
import CustomerUpdate from 'pages/site-h2i/customers/registration/update/CustomerUpdate';
import CustomerNew from 'pages/site-h2i/customers/registration/new/CustomerNew';
import Agents from 'pages/agents/Agents';
import CreditLimitRequests from 'pages/credit-limit-requests/CreditLimitRequests';
import CreditAnalysisQuestions from 'pages/credit-analysis-questions/CreditAnalysisQuestions';
import NewCreditAnalysisQuestion from 'pages/credit-analysis-questions/registration/new/CreditAnalysisQuestion';
import EditCreditAnalysisQuestion from 'pages/credit-analysis-questions/registration/update/CreditAnalysisQuestion';
import Customers from 'pages/customers/Customers';
import ItemsSoldReport from 'pages/reports/items-sold/ItemsSoldReport';
import SaleActivityReport from 'pages/reports/sales-activity/saleActivityReport';
import CommissionCategoryReport from 'pages/reports/commission-category/CommissionCategoryReport';
import SellerCommissionReport from 'pages/reports/commission/SellerCommissionReport';
import DashboardFinancial from 'pages/dashboard/financial/DashboardFinancial';
import PromotionalBanners from 'pages/promotional-banners/PromotionalBanners';
import PromotionalBannerNew from 'pages/promotional-banners/registration/new/PromotionalBannerNew';
import PromotionalBannerUpdate from 'pages/promotional-banners/registration/update/PromotionalBannerUpdate';
import Projects from 'pages/projects/Projects';
import InvoiceCard from 'pages/commercial/authorization/card/InvoiceCard';
import InvoiceBilling from 'pages/commercial/authorization/billing/InvoiceBilling';
import Templates from 'pages/templates/Templates';
import TemplateUpdate from 'pages/templates/registration/update/TemplateUpdate';
import TemplateNew from 'pages/templates/registration/new/TemplateNew';
import PurchaseOrder from 'pages/purchase/order/PurchaseOrder';
import PurchaseOrderNewPage from 'pages/purchase/order/registration/new/PurchaseOrderNew';
import FiscalCode from 'pages/cfop/FiscalCode';
import FiscalCodeNew from 'pages/cfop/registration/new/FiscalCodeNew';
import FiscalCodeUpdate from 'pages/cfop/registration/edit/FiscalCodeUpdate';
import AiArtPage from 'pages/ai/art/AiArtPage';
import AiChat from 'pages/ai/chat/AiChat';
import AiProduct from 'pages/ai/product/AiProduct';
import PriceProtectionNew from 'pages/price-protection/registration/PriceProtectionNew';
import PriceProtectionPage from 'pages/price-protection/PriceProtection';
import DavReviewPage from 'pages/dav-review/DavReviewPage';
import FinancialPage from 'pages/financial/FinancialPage';
import ReportsPage from 'pages/reports/ReportsPage';
import ProjectNew from 'pages/projects/registration/new/ProjectNew';
import ProjectUpdate from 'pages/projects/registration/update/ProjectUpdate';
import Compatibilities from 'pages/compatibilities/Compatibilities';
import CompatibilityNew from 'pages/compatibilities/registration/new/CompatibilityNew';
import CompatibilityUpdate from 'pages/compatibilities/registration/update/CompatibilityUpdate';
import RelatedProducts from 'pages/related-product/RelatedProducts';
import RelatedProductNew from 'pages/related-product/registration/new/RelatedProductNew';
import RelatedProductUpdate from 'pages/related-product/registration/update/RelatedProductUpdate';
import Categories from 'pages/categories/Categories';
import Rentals from 'pages/rentals/Rentals';
import RentalNew from 'pages/rentals/registration/new/RentalNew';
import RentalUpdate from 'pages/rentals/registration/update/RentalUpdate';
import ManagedSales from 'pages/managed-sales/ManagedSales';
import ManagedSaleNew from 'pages/managed-sales/registration/new/ManagedSaleNew';
import ManagedSaleUpdate from 'pages/managed-sales/registration/update/ManagedSaleUpdate';
import DavReviewDetail from 'pages/dav-review/selected-dav/DavReviewDetail';
import CourseStudents from 'pages/course-students/CourseStudents';
import CourseStudentNew from 'pages/course-students/registration/new/CourseStudentNew';
import CourseStudentUpdate from 'pages/course-students/registration/update/CourseStudentUpdate';
import JustificationPage from 'pages/rh/justification/Justification';
import JustificationNew from 'pages/rh/justification/registration/new/JustificationNew';
import Requirements from 'pages/rh/collaborator/Requirements';
import RequirementNew from 'pages/rh/collaborator/registration/new/RequirementNew';
import Wastes from 'pages/material-residues/waste/Wastes';
import WasteNew from 'pages/material-residues/waste/registration/new/WasteNew';
import WasteUpdate from 'pages/material-residues/waste/registration/update/WasteUpdate';
import WasteStock from 'pages/material-residues/stock/WasteStock';
import AnalysisProduct from 'pages/purchase/analysis-product/AnalysisProduct';
import PurchaseOrderUpdate from 'pages/purchase/order/registration/update/PurchaseOrderUpdate';
import PurchaseOrderReport from 'pages/purchase/order/report/PurchaseOrderReport';
import CommercialBi from 'pages/bi/commercial/CommercialBi';
import Buyers from 'pages/buyers/Buyers';
import WasteReport from 'pages/material-residues/waste/report/WasteReport';
import CurriculumPage from 'pages/rh/curriculum/Curriculum';
import VacancyPage from 'pages/rh/vacancy/Vacancy';
import VacancyNew from 'pages/rh/vacancy/registration/new/VacancyNew';
import VacancyUpdate from 'pages/rh/vacancy/registration/update/VacancyUpdate';
import WasteReports from 'pages/material-residues/waste/reports/WasteReports';
import CommercialDashboard from 'pages/commercial/dashboard/CommercialDashboard';
import CommercialAnalysisProduct from 'pages/bi/analysis-product/CommercialAnalysisProduct';
import ItemEntryReport from 'pages/reports/item-entry/report/ItemEntryReport';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Error404 />} />

      <Route path="/login" element={<PublicRoute element={<Login />} />} />
      <Route path="/forgot" element={<PublicRoute element={<Forgot />} />} />
      <Route path="/password-reset/:token" element={<PublicRoute element={<PasswordReset />} />} />

      <Route path="/" element={<ProtectedRoute element={<Home />} />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<Home />} />} />
      <Route path="/account" element={<ProtectedRoute element={<Account />} />} />
      <Route path="/users" element={<ProtectedRoute rule="settings.user_control.users" element={<Users />} />} />
      <Route path="/user" element={<ProtectedRoute rule="settings.user_control.users" element={<NewUser />} />} />
      <Route path="/user/:id" element={<ProtectedRoute rule="settings.user_control.users" element={<EditUser />} />} />

      <Route
        path="/profiles"
        element={<ProtectedRoute rule="settings.user_control.users_profiles" element={<Profiles />} />}
      />
      <Route
        path="/profile"
        element={<ProtectedRoute rule="settings.user_control.users_profiles" element={<NewProfile />} />}
      />
      <Route
        path="/profile/:id"
        element={<ProtectedRoute rule="settings.user_control.users_profiles" element={<EditProfile />} />}
      />

      <Route path="/order-authorization/card" element={<ProtectedRoute element={<InvoiceCard />} />} />
      <Route path="/order-authorization/bill" element={<ProtectedRoute element={<InvoiceBilling />} />} />

      <Route path="/projects" element={<ProtectedRoute element={<Projects />} />} />

      <Route
        path="/user-profiles"
        element={<ProtectedRoute rule="settings.user_control.users_profiles" element={<UserProfiles />} />}
      />
      <Route
        path="/user-profile"
        element={<ProtectedRoute rule="settings.user_control.users_profiles" element={<NewUserProfile />} />}
      />
      <Route
        path="/user-profile/:id"
        element={<ProtectedRoute rule="settings.user_control.users_profiles" element={<EditUserProfile />} />}
      />

      <Route path="/settings" element={<ProtectedRoute rule="settings.control_panel" element={<Settings />} />} />

      <Route
        path="/reports/items-sold"
        element={<ProtectedRoute rule="reports_sales_itemssold" element={<ItemsSoldReport />} />}
      />

      <Route
        path="/reports/sellers-commission"
        element={<ProtectedRoute rule="reports" element={<SellerCommissionReport />} />}
      />
      <Route
        path="/reports/commission-categories"
        element={<ProtectedRoute rule="reports" element={<CommissionCategoryReport />} />}
      />

      <Route
        path="/reports/sale-activity"
        element={<ProtectedRoute rule="reports" element={<SaleActivityReport />} />}
      />

      <Route
        path="/price-protection/new"
        element={<ProtectedRoute rule="registrations.price-protection" element={<PriceProtectionNew />} />}
      />
      <Route
        path="/price-protection"
        element={<ProtectedRoute rule="registrations.price-protection" element={<PriceProtectionPage />} />}
      />

      <Route path="/products" element={<ProtectedRoute rule="registrations_product" element={<Products />} />} />
      <Route path="/products/:id" element={<ProtectedRoute rule="registrations_product" element={<Product />} />} />

      <Route
        path="/access-rules"
        element={<ProtectedRoute rule="settings.user_control.access_rules" element={<AccessRules />} />}
      />
      <Route
        path="/access-rule/:id"
        element={<ProtectedRoute rule="settings.user_control.access_rules" element={<EditAccessRule />} />}
      />
      <Route
        path="/access-rule"
        element={<ProtectedRoute rule="settings.user_control.access_rules" element={<NewAccessRule />} />}
      />

      <Route
        path="/email-templates"
        element={<ProtectedRoute rule="settings.email_templates" element={<EmailTemplates />} />}
      />
      <Route
        path="/email-template"
        element={<ProtectedRoute rule="settings.email_templates" element={<EmailTemplateNew />} />}
      />
      <Route
        path="/email-template/:id"
        element={<ProtectedRoute rule="settings.email_templates" element={<EmailTemplateUpdate />} />}
      />

      <Route
        path="/config/app/banners"
        element={<ProtectedRoute rule="settings.app.banners" element={<AppBanners />} />}
      />
      <Route
        path="/config/app/banner"
        element={<ProtectedRoute rule="settings.app.banners" element={<AppBannerNew />} />}
      />
      <Route
        path="/config/app/banner/:id"
        element={<ProtectedRoute rule="settings.app.banners" element={<AppBannerUpdate />} />}
      />

      <Route
        path="/site/slider-banners"
        element={<ProtectedRoute rule="settings.site.banners" element={<SliderBanners />} />}
      />
      <Route
        path="/site/slider-banner"
        element={<ProtectedRoute rule="settings.site.banners" element={<SiteBannerNew />} />}
      />
      <Route
        path="/site/slider-banner/:id"
        element={<ProtectedRoute rule="settings.site.banners" element={<SiteBannerUpdate />} />}
      />

      <Route
        path="/site/promotional-banners"
        element={<ProtectedRoute rule="settings.site.banners" element={<PromotionalBanners />} />}
      />
      <Route
        path="/site/promotional-banner"
        element={<ProtectedRoute rule="settings.site.banners" element={<PromotionalBannerNew />} />}
      />
      <Route
        path="/site/promotional-banner/:id"
        element={<ProtectedRoute rule="settings.site.banners" element={<PromotionalBannerUpdate />} />}
      />

      <Route path="/site/templates" element={<ProtectedRoute rule="settings.site.banners" element={<Templates />} />} />
      <Route
        path="/site/template/new"
        element={<ProtectedRoute rule="settings.site.banners" element={<TemplateNew />} />}
      />
      <Route
        path="/site/template/:id"
        element={<ProtectedRoute rule="settings.site.banners" element={<TemplateUpdate />} />}
      />

      <Route
        path="/site/newsletter-subscribers"
        element={<ProtectedRoute rule="settings.site.newsletter" element={<NewsletterSubscribers />} />}
      />

      <Route path="/site/partners" element={<ProtectedRoute rule="settings.site.partners" element={<Partners />} />} />
      <Route path="/site/partner" element={<ProtectedRoute rule="settings.site.partners" element={<PartnerNew />} />} />
      <Route
        path="/site/partner/:id"
        element={<ProtectedRoute rule="settings.site.partners" element={<PartnerUpdate />} />}
      />

      <Route path="/site/branches" element={<ProtectedRoute rule="settings.site.branches" element={<Branches />} />} />
      <Route path="/site/branch" element={<ProtectedRoute rule="settings.site.branches" element={<BranchNew />} />} />
      <Route
        path="/site/branch/:id"
        element={<ProtectedRoute rule="settings.site.branches" element={<BranchUpdate />} />}
      />

      <Route
        path="/site/settings"
        element={<ProtectedRoute rule="settings.site.settings" element={<SiteSettings />} />}
      />

      <Route path="/courses" element={<ProtectedRoute rule="commercial.courses" element={<Courses />} />} />
      <Route path="/courses/new" element={<ProtectedRoute rule="commercial.courses" element={<CourseNew />} />} />
      <Route path="/courses/:id" element={<ProtectedRoute rule="commercial.courses" element={<CourseUpdate />} />} />

      <Route path="/cfop" element={<ProtectedRoute rule="registrations.cfop" element={<FiscalCode />} />} />
      <Route path="/cfop/new" element={<ProtectedRoute rule="registrations.cfop.new" element={<FiscalCodeNew />} />} />
      <Route
        path="/cfop/:id"
        element={<ProtectedRoute rule="registrations.cfop.update" element={<FiscalCodeUpdate />} />}
      />

      <Route
        path="/resellers"
        element={<ProtectedRoute rule="registrations.resellers.waiting_approvement" element={<Resellers />} />}
      />
      <Route
        path="/approved-resellers"
        element={<ProtectedRoute rule="registrations.resellers.approved" element={<Resellers />} />}
      />
      <Route path="/rejected-resellers" element={<ProtectedRoute element={<Resellers />} />} />

      <Route path="/updated-resellers" element={<ProtectedRoute element={<Resellers />} />} />
      <Route path="/updated-approved-resellers" element={<ProtectedRoute element={<Resellers />} />} />
      <Route path="/updated-rejected-resellers" element={<ProtectedRoute element={<Resellers />} />} />

      <Route path="/completed-credit-requests" element={<ProtectedRoute element={<CreditLimitRequests />} />} />
      <Route path="/analysed-credit-requests" element={<ProtectedRoute element={<CreditLimitRequests />} />} />

      <Route path="/site/banners" element={<ProtectedRoute rule="settings.site.banners" element={<Banners />} />} />
      <Route path="/site/banner" element={<ProtectedRoute rule="settings.site.banners" element={<BannerNew />} />} />
      <Route
        path="/site/banner/:id"
        element={<ProtectedRoute rule="settings.site.banners" element={<BannerUpdate />} />}
      />

      <Route path="/site/pages" element={<ProtectedRoute rule="settings.site.pages" element={<Pages />} />} />
      <Route path="/site/page" element={<ProtectedRoute rule="settings.site.pages" element={<PageNew />} />} />
      <Route path="/site/page/:id" element={<ProtectedRoute rule="settings.site.pages" element={<PageUpdate />} />} />

      <Route
        path="/site/customers"
        element={<ProtectedRoute rule="settings.site.customers" element={<SiteH2iCustomers />} />}
      />
      <Route
        path="/site/customer"
        element={<ProtectedRoute rule="settings.site.customers" element={<CustomerNew />} />}
      />
      <Route
        path="/site/customer/:id"
        element={<ProtectedRoute rule="settings.site.customers" element={<CustomerUpdate />} />}
      />

      <Route path="/categories" element={<ProtectedRoute element={<Categories />} />} />

      <Route path="/agents" element={<ProtectedRoute rule="registrations.agents" element={<Agents />} />} />

      <Route path="/credit-analysis-questions" element={<ProtectedRoute element={<CreditAnalysisQuestions />} />} />
      <Route path="/credit-analysis-question" element={<ProtectedRoute element={<NewCreditAnalysisQuestion />} />} />
      <Route
        path="/credit-analysis-question/:id"
        element={<ProtectedRoute element={<EditCreditAnalysisQuestion />} />}
      />

      <Route path="/customers" element={<ProtectedRoute rule="registrations.customers" element={<Customers />} />} />

      <Route path="/buyers" element={<ProtectedRoute rule="registrations.buyers" element={<Buyers />} />} />

      <Route path="/dashboard/commercial" element={<ProtectedRoute rule="dashboard" element={<Dashboard />} />} />
      <Route
        path="/dashboard/financial"
        element={<ProtectedRoute rule="dashboard" element={<DashboardFinancial />} />}
      />

      <Route
        path="/purchasing/analysis-product"
        element={<ProtectedRoute rule="purchasing.analysis_product" element={<AnalysisProduct />} />}
      />

      <Route
        path="/commercial-bi/analysis-product/:id"
        element={<ProtectedRoute element={<CommercialAnalysisProduct />} />}
      />

      <Route
        path="/purchasing/:route"
        element={<ProtectedRoute rule="purchasing.order" element={<PurchaseOrder />} />}
      />
      <Route path="/purchasing/registration/print/:id" element={<PublicRoute element={<PurchaseOrderReport />} />} />
      <Route
        path="/purchasing/order/:id"
        element={<ProtectedRoute rule="purchasing.order" element={<PurchaseOrderUpdate />} />}
      />
      <Route
        path="/purchasing/order/new"
        element={<ProtectedRoute rule="purchasing.order" element={<PurchaseOrderNewPage />} />}
      />

      <Route path="/ai/art" element={<ProtectedRoute rule="ai.art" element={<AiArtPage />} />} />
      <Route path="/ai/chat" element={<ProtectedRoute rule="ai.chat" element={<AiChat />} />} />
      <Route path="/ai/product" element={<ProtectedRoute rule="ai.product" element={<AiProduct />} />} />

      <Route path="/rh/justification" element={<ProtectedRoute rule="rh" element={<JustificationPage />} />} />
      <Route path="/rh/justification/new" element={<ProtectedRoute rule="rh" element={<JustificationNew />} />} />

      <Route path="/rh/requirements" element={<ProtectedRoute element={<Requirements />} />} />
      <Route path="/rh/requirement/new" element={<ProtectedRoute element={<RequirementNew />} />} />

      <Route
        path="/rh/curriculum"
        element={<ProtectedRoute rule="rh.recruitment.curriculum" element={<CurriculumPage />} />}
      />

      <Route
        path="/rh/vacancies"
        element={<ProtectedRoute rule="rh.recruitment.vacancies" element={<VacancyPage />} />}
      />
      <Route
        path="/rh/vacancy/new"
        element={<ProtectedRoute rule="rh.recruitment.vacancies" element={<VacancyNew />} />}
      />
      <Route
        path="/rh/vacancy/:job_id"
        element={<ProtectedRoute rule="rh.recruitment.vacancies" element={<VacancyUpdate />} />}
      />

      <Route path="/review/dav" element={<ProtectedRoute rule="commercial.review.dav" element={<DavReviewPage />} />} />
      <Route
        path="/review/dav/:id/:branchId"
        element={<ProtectedRoute rule="commercial.review.dav" element={<DavReviewDetail />} />}
      />

      <Route
        path="/commercial/dashboard"
        element={<ProtectedRoute rule="commercial.dashboard" element={<CommercialDashboard />} />}
      />

      <Route path="/financial/:param" element={<ProtectedRoute rule="financial" element={<FinancialPage />} />} />
      <Route path="/reports/:param" element={<ProtectedRoute rule="reports" element={<ReportsPage />} />} />
      <Route path="/reports" element={<ProtectedRoute rule="reports" element={<ReportsPage />} />} />
      <Route
        path="/reports/item_entry/report"
        element={<ProtectedRoute rule="reports" element={<ItemEntryReport />} />}
      />

      <Route path="/stock/waste" element={<ProtectedRoute element={<WasteStock />} />} />

      <Route path="/waste" element={<ProtectedRoute element={<Wastes />} />} />
      <Route path="/waste/new" element={<ProtectedRoute element={<WasteNew />} />} />
      <Route path="/waste/:id" element={<ProtectedRoute element={<WasteUpdate />} />} />
      <Route path="/waste/print/:id" element={<ProtectedRoute element={<WasteReport />} />} />
      <Route path="/waste/print" element={<ProtectedRoute element={<WasteReports />} />} />

      <Route path="/projects/new" element={<ProtectedRoute element={<ProjectNew />} />} />
      <Route path="/projects/:id" element={<ProtectedRoute element={<ProjectUpdate />} />} />

      <Route path="/compatibilities" element={<ProtectedRoute element={<Compatibilities />} />} />
      <Route path="/compatibilities/new" element={<ProtectedRoute element={<CompatibilityNew />} />} />
      <Route path="/compatibilities/:id" element={<ProtectedRoute element={<CompatibilityUpdate />} />} />

      <Route path="/related-products" element={<ProtectedRoute element={<RelatedProducts />} />} />
      <Route path="/related-products/new" element={<ProtectedRoute element={<RelatedProductNew />} />} />
      <Route path="/related-products/:id" element={<ProtectedRoute element={<RelatedProductUpdate />} />} />

      <Route path="/rentals" element={<ProtectedRoute element={<Rentals />} />} />
      <Route path="/rentals/new" element={<ProtectedRoute element={<RentalNew />} />} />
      <Route path="/rentals/:id" element={<ProtectedRoute element={<RentalUpdate />} />} />

      <Route path="/managed-sales" element={<ProtectedRoute element={<ManagedSales />} />} />
      <Route path="/managed-sales/new" element={<ProtectedRoute element={<ManagedSaleNew />} />} />
      <Route path="/managed-sales/:id" element={<ProtectedRoute element={<ManagedSaleUpdate />} />} />

      <Route path="/course-students" element={<ProtectedRoute element={<CourseStudents />} />} />
      <Route path="/course-students/new" element={<ProtectedRoute element={<CourseStudentNew />} />} />
      <Route path="/course-students/:id" element={<ProtectedRoute element={<CourseStudentUpdate />} />} />

      <Route path="/commercial-bi" element={<ProtectedRoute element={<CommercialBi />} />} />
    </Routes>
  );
};

export default AppRoutes;
