import React from 'react';
import { useCommercialBi } from '../hooks/useCommercialBi';
import { Typography, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import NoData from 'components/no-data/NoData';
import { SalesItem } from 'types/reports/sales';
import history from 'services/history';

const Container = styled('div')({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  gridColumn: '1/3',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
});

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#eeeff8',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '40px 1fr 30px 70px 70px',
  '& .sale-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const TopItemsSales: React.FC = () => {
  const { rowsPerPage, page } = usePagination();
  const { salesItems } = useCommercialBi();

  const itemsReport = salesItems.sort((a, b) => {
    if (a.qtd_vendas < b.qtd_vendas) {
      return 1;
    }

    if (a.qtd_vendas > b.qtd_vendas) {
      return -1;
    }

    return 0;
  });

  function handleRedirect(sale: SalesItem) {
    history.push(`/commercial-bi/analysis-product/${sale.codigo}`);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Relação de itens vendidos</Typography>
      </Title>
      {salesItems.length > 0 ? (
        <Content>
          <List>
            <Row className="header">
              <Typography variant="caption" fontWeight={600}>
                Cod
              </Typography>
              <Typography variant="caption" fontWeight={600}>
                Produto
              </Typography>
              <Typography align="right" variant="caption" fontWeight={600}>
                Qtd
              </Typography>
              <Typography align="right" variant="caption" fontWeight={600}>
                Total
              </Typography>
              <Typography align="right" variant="caption" fontWeight={600}>
                MC
              </Typography>
            </Row>
            {itemsReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => (
              <Row className="item" onClick={() => handleRedirect(sale)} key={index}>
                <Typography variant="caption">{sale.codigo}</Typography>
                <Typography className="sale-name" variant="caption">
                  {sale.produto}
                </Typography>
                <Typography align="right" variant="caption">
                  {sale.qtd_vendas}
                </Typography>

                <Typography align="right" variant="caption">
                  {sale.formattedTotal}
                </Typography>

                <Typography align="right" variant="caption">
                  {sale.mc}
                </Typography>
              </Row>
            ))}
          </List>
          <Pagination count={itemsReport.length} />
        </Content>
      ) : (
        <NoData message="Nenhum registro encontrado" />
      )}
    </Container>
  );
};

export default TopItemsSales;
