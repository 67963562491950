import React from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFetchCommercialAnalysisProduct } from './hooks/useFetchCommercialAnalysisProduct';
import CommercialAnalysisProductFilterBox from './CommercialAnalysisProductFilterBox';
import CommercialAnalysisProductHeader from './CommercialAnalysisProductHeader';
import { styled } from '@mui/material';
import CommercialAnalysisProductReports from './CommercialAnalysisProductReports';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const CommercialAnalysisProduct: React.FC = () => {
  const { product, handleSearchSubmit, loading, handleChangeFilter, filter } = useFetchCommercialAnalysisProduct();
  const [displayMode] = useDisplayMode();

  return (
    <>
      <Appbar title="Análise de produto" />

      <CommercialAnalysisProductFilterBox
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : !product ? (
        <NoData message="Produto não encontrado" />
      ) : (
        <Container>
          <CommercialAnalysisProductHeader product={product} />

          <CommercialAnalysisProductReports product={product} />
        </Container>
      )}
    </>
  );
};

export default CommercialAnalysisProduct;
