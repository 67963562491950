import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Button, IconButton, Typography, styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { endOfDay, startOfMonth } from 'date-fns';
import FinancialCardMenu from './FinancialCardMenu';
import CardListTable from './list/table/CardListTable';
import CardListModule from './list/module/CardListModule';
import FinancialCardData from './FinancialCardData';
import FinancialCardFilterPage from './FinancialCardFilter';
import { FinancialCardProvider } from './hooks/useFinancialCard';
import { FinancialCard } from 'types/financialReportList';
import SalesCardReportTotal from './SalesCardReportTotal';
import FinancialCardFilterBox from './FinancialCardFilterBox';
import FilterActions from 'components/filter-box/FilterActions';
import FlagListTable from './flag/list/table/FlagListTable';
import FlagListModule from './flag/list/module/FlagListModule';
import BranchListTable from './branch/list/table/BranchListTable';
import BranchListModule from './branch/list/module/BranchListModule';
import BranchChart from './chart/BranchChart';
import ReportLoading from 'components/loading/ReportLoading';
import { FinancialCardBranch, FinancialCardFlag, useFetchFinancialCardReport } from './hooks/useFetchFinancialCard';
import { useSelector } from 'store/selector';
import { usePermissionRules } from 'hooks/permissionRules';
import FinancialCardExport from './export/FinancialCardExport';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import { Print } from '@mui/icons-material';

let timer: NodeJS.Timeout;

export interface FinancialCardFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const MoreDetail = styled('div')(({ theme }) => ({
  marginTop: 15,
  marginBottom: 10,
  padding: '15px 0',
  position: 'relative',
  transition: 'all ease 0.3s',
  border: `2px solid ${theme.palette.primary.main}`,
}));

const CustomTypography = styled(Typography)({
  top: -12,
  left: 15,
  cursor: 'pointer',
  padding: '0px 5px',
  position: 'absolute',
  backgroundColor: '#fafafa',
});

export interface FinancialCardFilterParams {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
  typeDate: string;
  situation: string;
}

const FinancialCardReport: React.FC = () => {
  const { handleBack } = useFinancial();
  const { windowWidth, isMobile } = useApp();
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const [viewList, setViewList] = useState(false);
  const [viewFlag, setViewFlag] = useState(false);
  const [viewChart, setViewChart] = useState(false);
  const [viewBranch, setViewBranch] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [filteredCard, setFilteredCard] = useState<FinancialCard[]>([]);
  const [filteredFlag, setFilteredFlag] = useState<FinancialCardFlag[]>([]);
  const [filteredBranch, setFilteredBranch] = useState<FinancialCardBranch[]>([]);
  const [excelExport, setExcelExport] = useState(false);
  const { checkPermission } = usePermissionRules();
  const [filter, setFilter] = useState<FinancialCardFilterParams>({
    initialDate: startOfMonth(new Date()),
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    situation: 'T',
    typeDate: 'V',
  });
  const [handleSearch, cards, loading, formattedTotal, financialCardFlag, financialCardBranch] =
    useFetchFinancialCardReport(filter);

  const selectedBranch = useMemo(() => {
    const branch = branches.find(branch => branch.id === filter.branchId);

    if (!branch) {
      return null;
    }

    return branch;
  }, [filter.branchId, branches]);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredCard(cards);
  }, [cards]);

  useEffect(() => {
    setFilteredBranch(financialCardBranch);
  }, [financialCardBranch]);

  useEffect(() => {
    setFilteredFlag(financialCardFlag);
  }, [financialCardFlag]);

  function handleChangeFilter(index: keyof FinancialCardFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSortBranch(index: string) {
    const filtered = sort(index, filteredBranch);
    setFilteredBranch(filtered);
  }

  function handleSortFlag(index: string) {
    const filtered = sort(index, filteredFlag);
    setFilteredFlag(filtered);
  }

  function handleSort(index: string) {
    const filtered = sort(index, filteredCard);
    setFilteredCard(filtered);
  }

  function handleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    handleSearch(searchText);
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);

    clearTimeout(timer);

    if (!value.length) return;

    timer = setTimeout(() => handleSearch(value), 800);
  }

  return (
    <FinancialCardProvider
      value={{
        filter,
        handleChangeFilter,
      }}
    >
      {dialogDate && <FinancialCardFilterPage onExited={() => setDialogDate(false)} />}

      {excelExport && (
        <FinancialCardExport onExited={() => setExcelExport(false)} cards={cards} flags={financialCardFlag} />
      )}

      {shownReport && <FinancialCardData data={cards} branch={selectedBranch} onExited={() => setShownReport(false)} />}

      <Appbar
        backAction={handleBack}
        title="Cartões"
        ActionsComponent={
          <>
            {windowWidth < 600 && isMobile && (
              <>
                <IconButton color="inherit" onClick={() => setShownReport(true)}>
                  <Print />
                </IconButton>
                <FilterActions handleOpenDialog={() => setDialogDate(true)} />
              </>
            )}
          </>
        }
      />
      <PageHeaderActions
        title="Relação de cartões"
        description="Gerencie os cartões"
        ActionComponent={
          <>
            <Button size="small" color="primary" variant="contained" onClick={() => setShownReport(true)}>
              Imprimir
            </Button>

            {checkPermission('general.excel_export_button') && (
              <Button
                disabled={cards.length === 0}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setExcelExport(true)}
              >
                Excel
              </Button>
            )}
          </>
        }
      />

      <FinancialCardFilterBox
        filter={filter}
        displayMode={displayMode}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        setDisplayMode={setDisplayMode}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      {loading ? (
        <ReportLoading displayMode={displayMode} />
      ) : filteredCard.length === 0 ? (
        <NoData message="Nenhum cartão para mostrar" />
      ) : (
        <>
          <SalesCardReportTotal formattedTotal={formattedTotal} />
          <div>
            <MoreDetail>
              <CustomTypography onClick={() => setViewChart(!viewChart)} variant="caption">
                + Gráficos
              </CustomTypography>
              {viewChart && (
                <>
                  <BranchChart formattedTotalChart={financialCardBranch} />
                </>
              )}
            </MoreDetail>

            <MoreDetail>
              <CustomTypography onClick={() => setViewBranch(!viewBranch)} variant="caption">
                + Filiais
              </CustomTypography>
              {viewBranch && (
                <PaginationProvider>
                  <Container>
                    {displayMode === 'list' ? (
                      <BranchListTable
                        branches={filteredBranch}
                        handleSort={handleSortBranch}
                        orderedIndex={orderedIndex}
                      />
                    ) : (
                      <BranchListModule branches={filteredBranch} />
                    )}
                    <Pagination count={filteredBranch.length} />
                  </Container>
                </PaginationProvider>
              )}
            </MoreDetail>

            <MoreDetail>
              <CustomTypography onClick={() => setViewFlag(!viewFlag)} variant="caption">
                + Bandeira
              </CustomTypography>
              {viewFlag && (
                <PaginationProvider>
                  <Container>
                    {displayMode === 'list' ? (
                      <FlagListTable flags={filteredFlag} handleSort={handleSortFlag} orderedIndex={orderedIndex} />
                    ) : (
                      <FlagListModule flags={filteredFlag} />
                    )}
                    <Pagination count={filteredFlag.length} />
                  </Container>
                </PaginationProvider>
              )}
            </MoreDetail>

            <MoreDetail>
              <CustomTypography onClick={() => setViewList(!viewList)} variant="caption">
                + Detalhamento
              </CustomTypography>
              {viewList && (
                <PaginationProvider>
                  <Container>
                    <FinancialCardMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                    {displayMode === 'list' ? (
                      <CardListTable
                        setAnchorEl={setAnchorEl}
                        cards={filteredCard}
                        handleSort={handleSort}
                        orderedIndex={orderedIndex}
                      />
                    ) : (
                      <CardListModule cards={filteredCard} />
                    )}
                    <Pagination count={filteredCard.length} />
                  </Container>
                </PaginationProvider>
              )}
            </MoreDetail>
          </div>
        </>
      )}
    </FinancialCardProvider>
  );
};

export default FinancialCardReport;
