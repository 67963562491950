import React from 'react';
import { Typography, styled, ListItem } from '@mui/material';
import { ItemEntry } from 'types/itemEntry';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '130px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type ItemEntryItemModuleProps = {
  entry: ItemEntry;
};

const ItemEntryItemModule: React.FC<ItemEntryItemModuleProps> = ({ entry }) => {
  return (
    <ListItemStyled>
      <Grid className="bottom">
        <Typography variant="caption">Filial:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.filial}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Produto:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.produto}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Fornecedor:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.fornecedor}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Data Entrada:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.data_entrada}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">NF:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.nf}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Custo Total:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.formattedCoustTotal}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Custo Total Unitário:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.formattedCoustUnitaryTotal}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor Unitário:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.formattedUnitary}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor IPI:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {entry.formattedIpi}
        </Typography>
      </Grid>
    </ListItemStyled>
  );
};

export default ItemEntryItemModule;
