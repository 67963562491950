import { ChevronLeft } from '@mui/icons-material';
import { IconButton as DefaultIconButton, Typography, alpha, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FilterOption, useSidebarFilters } from './hooks/useSidebarFilters';
import SidebarFilter from './SidebarFilter';
import history from 'services/history';
import { filterStore } from 'pages/purchase/order/hooks/useFilterStore';

const OPENED_SIDEBAR_WIDTH_MODULE = 200;
const OPENED_SIDEBAR_WIDTH = 250;
const CLOSED_SIDEBAR_WIDTH = 0;

type SidebarProps = {
  isOpen: boolean;
};

const Sidebar = styled('div')<SidebarProps>(props => ({
  paddingLeft: props.isOpen ? OPENED_SIDEBAR_WIDTH : CLOSED_SIDEBAR_WIDTH,
  maxHeight: 'calc(100vh - 80px)',
  position: 'relative',
  background: alpha(props.theme.palette.primary.main, 0.08),
  '& > .content': {
    paddingTop: 15,
    position: 'absolute',
    width: OPENED_SIDEBAR_WIDTH,
    top: 50,
    left: 0,
    borderRight: '1px solid #eee',
    transition: 'transform 0.3s ease',
    transform: `translateX(${props.isOpen ? 0 : -OPENED_SIDEBAR_WIDTH}px)`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
    overflowY: props.isOpen ? 'auto' : 'hidden',
    height: 'calc(100% - 50px)',
    paddingBottom: 15,
  },
  transition: 'padding 0.3s ease',
  [props.theme.breakpoints.down('md')]: {
    paddingLeft: props.isOpen ? OPENED_SIDEBAR_WIDTH_MODULE : CLOSED_SIDEBAR_WIDTH,
    '& > .content': {
      width: OPENED_SIDEBAR_WIDTH_MODULE,
      transform: `translateX(${props.isOpen ? 0 : -OPENED_SIDEBAR_WIDTH_MODULE}px)`,
    },
  },
}));

const SidebarHeader = styled('div')<SidebarProps>(props => ({
  opacity: props.isOpen ? '1' : '0',
  display: 'flex',
  padding: 10,
  alignItems: 'center',
  justifyContent: 'space-between',
  background: props.theme.palette.primary.main,
  color: props.theme.palette.primary.contrastText,
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  height: 50,
}));

const ChevronIcon = styled(ChevronLeft)<SidebarProps>(props => ({
  transition: 'transform 0.4s ease',
  transform: props.isOpen ? 'rotate(180deg)' : 'rotate(0)',
}));

const IconButton = styled(DefaultIconButton)(props => ({
  background: props.theme.palette.primary.dark,
  color: '#fff',
  position: 'absolute',
  right: -15,
  zIndex: 100,
  top: 5,
  '&:hover': {
    background: props.theme.palette.primary.dark,
  },
}));

interface SidebarFiltersProps {
  onFilterClick?(query: string): void;
}

const SidebarFilters: React.FC<SidebarFiltersProps> = ({ onFilterClick }) => {
  const { isSidebarOpen, setIsSidebarOpen, filters } = useSidebarFilters();
  const [map, setMap] = useState<Map<string, string[]>>(new Map());

  useEffect(() => {
    if (window.location.search) {
      const search = new URLSearchParams(window.location.search);
      const initialMap = new Map<string, string[]>();

      search.forEach((value, key) => {
        initialMap.set(key, value.split(','));
      });

      setMap(initialMap);
    }
  }, [filters]);

  useEffect(() => {
    if (localStorage.getItem('filters')) {
      const search = new URLSearchParams(localStorage.getItem('filters') || '');
      const initialMap = new Map<string, string[]>();

      search.forEach((value, key) => {
        initialMap.set(key, value.split(','));
      });

      setMap(initialMap);
    }
  }, []);

  const handleClick = (value: FilterOption, checked: boolean, param: string) => {
    const updatedMap = new Map(map);
    const values = updatedMap.get(param);

    if (values) {
      updatedMap.set(param, checked ? [...values, value.id] : values.filter(id => id !== value.id));
    } else {
      updatedMap.set(param, [value.id]);
    }

    const urlSearchParams = new URLSearchParams();

    for (const [param, values] of updatedMap) {
      urlSearchParams.append(param, values.join(','));
    }

    history.push({
      pathname: history.location.pathname,
      search: urlSearchParams.toString(),
    });

    filterStore.setState(urlSearchParams.toString());
    setMap(updatedMap);
  };

  return (
    <Sidebar isOpen={isSidebarOpen}>
      <SidebarHeader isOpen={isSidebarOpen} style={{ transition: 'opacity 0.4s linear' }}>
        <Typography variant="subtitle2">Filtros</Typography>
      </SidebarHeader>

      <IconButton color="primary" size="small" onClick={() => setIsSidebarOpen(state => !state)}>
        <ChevronIcon isOpen={!isSidebarOpen} />
      </IconButton>
      <div className="content">
        {filters.map(filter => (
          <SidebarFilter map={map} key={filter.id} handleClick={handleClick} filter={filter} />
        ))}
      </div>
    </Sidebar>
  );
};

export default SidebarFilters;
