import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, TextField, Typography, Theme } from '@mui/material';
import Loading from 'components/loading/Loading';
import { numberFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { productChange, setPrices } from 'pages/registrations/products/reducer/actions';
import { useProduct } from '../../Product';
import ProductQuantityPrices from './quantity-price/ProductQuantityPrices';
import ProductQuantityPriceForm from './quantity-price/ProductQuantityPriceForm';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  inputPrimary: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.primary.main}!important`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  inputSuccess: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.success.main}!important`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.success.main,
    },
  },
  inputStandardSuccess: {
    '&:before': {
      borderColor: `${theme.palette.success.main}!important`,
    },
    '&:after': {
      borderColor: `${theme.palette.success.main}!important`,
    },
    '&:hover': {
      borderColor: theme.palette.success.main,
    },
  },
  inputStandardError: {
    '&:before': {
      borderColor: `${theme.palette.secondary.main}!important`,
    },
    '&:after': {
      borderColor: `${theme.palette.secondary.main}!important`,
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
  },
  labelSuccess: {
    color: `${theme.palette.success.main}!important`,
  },
  labelError: {
    color: `${theme.palette.secondary.main}!important`,
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
}));

let timer: NodeJS.Timeout;

const ProductPrices: React.FC = () => {
  const classes = useStyles();
  const { validation, product, dispatch } = useProduct();
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const messaging = useMessaging();
  const [values, setValues] = useState({
    custo: product.custo,
    margem_lucro: product.margem_lucro,
    preco_maximo_reee: product.preco_maximo_reee,
  });
  const { isMobile, windowWidth } = useApp();
  const [dialogQuantityPrice, setDialogQuantityPrice] = useState(false);

  const inputs = {
    custo: useRef<HTMLInputElement>(null),
    margem_lucro: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleCalcClick(payload: { custo: string | number; margem_lucro: string | number }) {
    setLoading(true);
    if (!h2iApi) return;

    h2iApi
      .get(`/api/CALCPRECO`, {
        params: {
          ID_PRODUTO: product.codigo_produto,
          CUSTO: numberFormat(payload.custo),
          MARGEM: numberFormat(payload.margem_lucro),
        },
      })
      .then(response => {
        const prices = response.data.RESULT[0][0];
        dispatch(setPrices(prices));
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível atualizar os preços');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handlePricesChange(index: 'custo' | 'margem_lucro' | 'preco_maximo_reee', value: any) {
    clearTimeout(timer);

    const p = {
      ...values,
      [index]: value,
    };

    setValues(p);
    dispatch(productChange(index, value));

    if (index !== 'preco_maximo_reee') {
      if (value !== '') timer = setTimeout(() => handleCalcClick(p), 500);
    }
  }

  return (
    <>
      {loading && <Loading />}
      {dialogQuantityPrice && <ProductQuantityPriceForm onExited={() => setDialogQuantityPrice(false)} />}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            inputRef={inputs.custo}
            error={!!validation.custo}
            helperText={validation.custo}
            label="Custo (R$)"
            placeholder="Digite o custo do produto"
            margin="normal"
            fullWidth
            value={values.custo}
            onChange={e => handlePricesChange('custo', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
            variant="outlined"
            InputProps={{
              classes: {
                root: classes.inputPrimary,
                focused: classes.inputPrimary,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            inputRef={inputs.margem_lucro}
            error={!!validation.margem_lucro}
            helperText={validation.margem_lucro}
            label="Margem (%)"
            placeholder="Digite a margem do produto"
            margin={!isMobile && windowWidth > 960 ? 'normal' : undefined}
            fullWidth
            value={values.margem_lucro}
            onChange={e => handlePricesChange('margem_lucro', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
            variant="outlined"
            InputProps={{
              classes: {
                root: classes.inputSuccess,
                focused: classes.inputSuccess,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelSuccess,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            label="Preço máximo R3E"
            placeholder="Preço máximo R3E"
            margin="normal"
            fullWidth
            value={values.preco_maximo_reee}
            onChange={e => handlePricesChange('preco_maximo_reee', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
            variant="outlined"
            InputProps={{
              classes: {
                root: classes.inputPrimary,
                focused: classes.inputPrimary,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            label="Preço 1"
            margin="normal"
            fullWidth
            value={product.p1}
            type="number"
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="Preço 2 PJ PR"
            margin="normal"
            fullWidth
            value={product.p2pjbr}
            type="number"
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="MC P2 PR"
            margin="normal"
            fullWidth
            value={product.mc_p2pjbr}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="Preço 2 PJ"
            margin="normal"
            fullWidth
            value={product.p2pj}
            type="number"
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="MC P2 PJ"
            margin="normal"
            fullWidth
            value={product.mc_p2pj}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="Preço 2 PF"
            margin="normal"
            fullWidth
            value={product.p2pf}
            type="number"
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="MC P2 PF"
            margin="normal"
            fullWidth
            value={product.mc_p2pf}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="Promo PJ"
            margin="normal"
            fullWidth
            value={product.preco_promopj}
            type="number"
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} xl={2} lg={2} md={3}>
          <TextField
            label="MC Promo PJ"
            margin="normal"
            fullWidth
            value={product.mc_promopj}
            InputProps={{
              classes: {
                underline: classes.inputStandardError,
                focused: classes.inputStandardError,
              },
            }}
            InputLabelProps={{
              classes: {
                focused: classes.labelError,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xl={8} lg={8} md={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
            <TextField
              label="Valor P.P"
              margin="normal"
              fullWidth
              value={product.pp}
              type="number"
              inputProps={{
                step: 0.01,
              }}
              InputProps={{
                classes: {
                  underline: classes.inputStandardSuccess,
                  focused: classes.inputStandardSuccess,
                },
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelSuccess,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
            <TextField
              label="Valor PMD"
              margin="normal"
              fullWidth
              value={product.pmd}
              type="number"
              inputProps={{
                step: 0.01,
              }}
              InputProps={{
                classes: {
                  underline: classes.inputStandardSuccess,
                  focused: classes.inputStandardSuccess,
                },
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelSuccess,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
            <TextField
              label="Valor PMC"
              margin="normal"
              fullWidth
              value={product.pmc}
              type="number"
              inputProps={{
                step: 0.01,
              }}
              InputProps={{
                classes: {
                  underline: classes.inputStandardSuccess,
                  focused: classes.inputStandardSuccess,
                },
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelSuccess,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {product.precoquantidade.length > 1 && (
        <Grid container>
          <Grid item xs={12} xl={4} lg={2} md={3} sm={6}>
            <div className={classes.action}>
              <Typography variant="subtitle1">Preços por quantidade</Typography>
              <Button onClick={() => setDialogQuantityPrice(true)} color="primary">
                Adicionar
              </Button>
            </div>
            <ProductQuantityPrices />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProductPrices;
