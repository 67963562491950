import { moneyFormat } from 'helpers/numberFormat';
import { ItemEntry } from 'types/itemEntry';

export interface AgroupedManufacturerItemEntry {
  fabricante: string;
  custo_total: number;
  qtd: number;
  formattedTotal: string;
}

export interface AgroupedSupplierItemEntry {
  fornecedor: string;
  custo_total: number;
  qtd: number;
  formattedTotal: string;
}

export interface AgroupedSegmentItemEntry {
  segmento: string;
  custo_total: number;
  formattedTotal: string;
}

export function useFetchItemEntryDashboard(items: ItemEntry[]) {
  function groupByManufacturer(data: ItemEntry[]): AgroupedManufacturerItemEntry[] {
    const manufacturerMap = new Map();

    data.forEach(item => {
      if (!manufacturerMap.has(item.fabricante)) {
        manufacturerMap.set(item.fabricante, { fabricante: item.fabricante, custo_total: 0, qtd: 0 });
      }
      const entry: AgroupedManufacturerItemEntry = manufacturerMap.get(item.fabricante);
      entry.custo_total += item.custo_total;
      entry.qtd += item.formattedQuantity;
      entry.formattedTotal = moneyFormat(entry.custo_total);
    });

    return Array.from(manufacturerMap.values());
  }

  function groupBySupplier(data: ItemEntry[]): AgroupedSupplierItemEntry[] {
    const supplierMap = new Map();

    data.forEach(item => {
      if (!supplierMap.has(item.fornecedor)) {
        supplierMap.set(item.fornecedor, { fornecedor: item.fornecedor, custo_total: 0, qtd: 0 });
      }
      const entry: AgroupedSupplierItemEntry = supplierMap.get(item.fornecedor);
      entry.custo_total += item.custo_total;
      entry.qtd += item.formattedQuantity;
      entry.formattedTotal = moneyFormat(entry.custo_total);
    });

    return Array.from(supplierMap.values());
  }

  function groupBySegment(data: ItemEntry[]): AgroupedSegmentItemEntry[] {
    const segmentMap = new Map();

    data.forEach(item => {
      if (!segmentMap.has(item.Segmento)) {
        segmentMap.set(item.Segmento, { segmento: item.Segmento, custo_total: 0 });
      }
      const entry = segmentMap.get(item.Segmento);
      entry.custo_total += item.custo_total;
      entry.formattedTotal = moneyFormat(entry.custo_total);
    });

    return Array.from(segmentMap.values());
  }

  const groupedManufacturers = groupByManufacturer(items).sort((a, b) => b.custo_total - a.custo_total);
  const groupedSuppliers = groupBySupplier(items).sort((a, b) => b.custo_total - a.custo_total);
  const groupedSegments = groupBySegment(items).sort((a, b) => b.custo_total - a.custo_total);

  return {
    groupedManufacturers,
    groupedSuppliers,
    groupedSegments,
  };
}
