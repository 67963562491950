import { useState, useCallback, FormEvent, useEffect } from 'react';
import { useApp } from 'hooks/app';
import { useSelector } from 'store/selector';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { ProductCharacteristic } from 'types/product';
import { useParams } from 'react-router-dom';

export interface CommercialAnalysisProductFilterParams {
  selectedBranchId: number;
  productId: number;
}

export function useFetchCommercialAnalysisProduct() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const { id } = useParams<{ id: string }>();
  const { isOpenedMenu, handleOpenMenu } = useApp();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<ProductCharacteristic | null>(null);
  const [filter, setFilter] = useState<CommercialAnalysisProductFilterParams>({
    selectedBranchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    productId: parseInt(id || '0'),
  });

  function handleChangeFilter(index: keyof CommercialAnalysisProductFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  const handleSearch = useCallback(() => {
    if (!h2iApi) return;

    setLoading(true);

    h2iApi
      .get(`/api/ProdutoID?id=${filter.productId}`)
      .then(_response => {
        const response = _response.data.RESULT[0];
        const p = response.map((item: ProductCharacteristic) => {
          item.formattedCusto = moneyFormat(item.custo);
          item.formattedMargem = percentFormat(item.margem_lucro);
          item.formattedP2PJ = numberFormat(item.p2pj);
          item.formattedP2BR = numberFormat(item.p2pjbr);
          item.formattedICMSCred = numberFormat(item.icms_cred);
          item.formattedICMSDeb = numberFormat(item.icms_deb);
          item.formattedPromoPJ = item.promo_pj ? 'Sim' : 'Não';
          item.formattedDestaque = item.destaque ? 'Sim' : 'Não';
          item.formattedPmc = numberFormat(item.pmc);
          item.formattedPmd = numberFormat(item.pmd);
          item.formattedPp = numberFormat(item.pp);
          return item;
        });

        setProduct(p[0]);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, h2iApi]);

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch();
  }

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return {
    product,
    handleSearchSubmit,
    loading,
    handleChangeFilter,
    filter,
  };
}
