import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { useSelector } from 'store/selector';
import Appbar from 'components/appbar/Appbar';
import { StockBalanceData } from 'types/reports/stockBalance';
import { StockFilter } from 'types/reports/stockFilter';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFetchWasteStock } from './hooks/useFetchWasteStock';
import { WasteStockProvider } from './hooks/useWasteStock';
import StockBalanceTotal from 'pages/reports/stock/StockBalanceTotal';
import StockBalanceExport from 'pages/reports/stock/StockBalanceExport';
import StockBalanceFilter from 'pages/reports/stock/StockBalanceFilter';
import StockMoreFilter from 'pages/reports/stock/StockMoreFilter';
import StockBalanceReportData from 'pages/reports/stock/stock-balance/StockBalanceReportData';
import ProductStockListTable from 'pages/reports/stock/stock-balance/list/product/table/ProductList';
import ProductStockListModule from 'pages/reports/stock/stock-balance/list/product/module/ProductList';
import PageHeader from 'components/page-header/PageHeader';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const WasteStock: React.FC = () => {
  const branches = useSelector(state => state.branches);
  const [excelExport, setExcelExport] = useState(false);
  const [filteredItems, setFiltered] = useState<StockBalanceData[]>([]);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [orderedIndex, sort] = useTableOrder();
  const [shownReport, setShownReport] = useState(false);
  const [filter, setFilter] = useState<StockFilter>({
    branchId: [],
    manufacturerId: [],
    subgroupId: [],
    indexToSearch: 'Produto',
    searchValue: '',
    categoryId: [],
    segmentId: [],
    subcategoryId: [],
    unityId: [],
    made_by: '',
  });
  const [moreFilter, setMoreFilter] = useState(false);
  const { stockBalance, formattedTotal, fetchStockBalance, loading } = useFetchWasteStock();

  function handleSort(index: string) {
    const p = sort(index, filteredItems);
    setFiltered(p);
  }

  function handleChange(index: keyof StockFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  useEffect(() => {
    setFiltered(stockBalance);
  }, [stockBalance]);

  const selectedBranches = useMemo(() => {
    const branch = branches.filter(branch => filter.branchId.some(item => item === branch.id));
    return branch;
  }, [branches, filter.branchId]);

  return (
    <WasteStockProvider
      value={{
        filter,
      }}
    >
      {moreFilter && (
        <StockMoreFilter
          filter={filter}
          onSearch={() => fetchStockBalance(filter)}
          onExited={() => setMoreFilter(false)}
          handleChangeFilter={handleChange}
        />
      )}

      {shownReport && (
        <StockBalanceReportData
          data={filteredItems}
          branches={selectedBranches}
          onExited={() => setShownReport(false)}
        />
      )}

      <Appbar title="R3E" />

      <PageHeader title="Saldo de Estoque R3E" description="Resíduos de Equipamentos Elétricos e Eletrônicos" />

      <StockBalanceFilter
        filter={filter}
        setExcelExport={setExcelExport}
        openMoreFilter={() => setMoreFilter(true)}
        openReport={() => setShownReport(true)}
        displayMode={displayMode}
        handleChange={handleChange}
        onSearch={filter => fetchStockBalance(filter)}
        setDisplayMode={setDisplayMode}
      />

      <StockBalanceTotal loading={loading} formattedTotal={formattedTotal} />

      {excelExport && <StockBalanceExport filter={filter} onExited={() => setExcelExport(false)} />}

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredItems.length === 0 ? (
        <NoData message="Nenhuma informação encontrada" />
      ) : (
        <>
          <PaginationProvider>
            <Container>
              {filteredItems.length === 0 ? (
                <NoData message="Nenhum item encontrado." />
              ) : displayMode === 'list' ? (
                <ProductStockListTable
                  isSubgroup={false}
                  products={filteredItems}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                displayMode === 'module' && <ProductStockListModule products={filteredItems} />
              )}
              <Pagination count={filteredItems.length} />
            </Container>
          </PaginationProvider>
        </>
      )}
    </WasteStockProvider>
  );
};

export default WasteStock;
