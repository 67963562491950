import React from 'react';
import List from 'components/list/List';
import ItemEntryItemModule from './ItemEntryItemModule';
import { ItemEntry } from 'types/itemEntry';

type ItemEntryListModuleProps = {
  entries: ItemEntry[];
};

const ItemEntryListModule: React.FC<ItemEntryListModuleProps> = ({ entries }) => {
  return (
    <>
      <List>
        {entries.map((entry, index) => (
          <ItemEntryItemModule key={index} entry={entry} />
        ))}
      </List>
    </>
  );
};

export default ItemEntryListModule;
