import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, styled } from '@mui/material';
import { useCustomer } from '../hooks/useCustomer';
import CustomerAgentTable from './table/CustomerAgentTable';
import CustomerAgentListMenu from './CustomerAgentListMenu';
import { Agent } from 'types/agent';
import { CustomerAgentProvider } from './hook/useCustomerAgents';
import PaginationProvider from 'hooks/pagination';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import { useApp } from 'hooks/app';
import FilterBox from 'components/filter-box/FilterBox';
import Pagination from 'components/pagination/Pagination';
import useTableOrder from 'hooks/tableOrder';
import CustomerAgentModule from './module/CustomerAgentModule';
import CustomerNewAgentDialog from './dialog/CustomerNewAgentDialog';
import CustomerEditAgentDialog from './dialog/CustomerEditAgentDialog';

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const NoData = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  background: '#fff',
  height: '100%',
  textAlign: 'center',
  verticalAlign: 'middle',
  marginTop: '150px',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  '& > button': {
    textTransform: 'none',
  },
});

const CustomerAgents: React.FC = () => {
  const { agents, handleSearchAgents, registerType, handleValidation } = useCustomer();
  const [newAgent, setNewAgent] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const app = useApp();
  const [filtered, setFiltered] = useState<Agent[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(
    app.isMobile || app.windowWidth < 930 ? 'module' : 'list',
  );

  useEffect(() => {
    setFiltered(agents);
  }, [agents]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  function onSave() {
    if (registerType === 'new') {
      handleValidation();
    }
    setNewAgent(false);
    setSelectedAgent(null);
    handleSearchAgents();
  }

  return (
    <>
      {newAgent && <CustomerNewAgentDialog onExited={() => setNewAgent(false)} onSave={onSave} />}
      <Actions>
        <Button variant="contained" onClick={() => setNewAgent(true)}>
          Adicionar preposto
        </Button>
      </Actions>
      {filtered.length < 1 ? (
        <NoData>
          <Typography color="textSecondary" variant="h6">
            Nenhum preposto encontrado para esse favorecido
          </Typography>
        </NoData>
      ) : (
        <CustomerAgentProvider value={{ selectedAgent, setSelectedAgent }}>
          <PaginationProvider>
            <Content>
              {selectedAgent && (
                <CustomerEditAgentDialog
                  selectedAgent={selectedAgent}
                  onExited={() => setSelectedAgent(null)}
                  onSave={onSave}
                />
              )}
              <CustomerAgentListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
              {displayMode === 'list' ? (
                <>
                  <CustomerAgentTable
                    setAnchorEl={setAnchorEl}
                    agents={filtered}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                  <FilterBox>
                    <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}></Grid>
                    <DisplayModeButtons
                      displayMode={displayMode}
                      handleShowList={() => setDisplayMode('list')}
                      handleShowModule={() => setDisplayMode('module')}
                    />
                  </FilterBox>
                </>
              ) : (
                <>
                  <CustomerAgentModule setAnchorEl={setAnchorEl} agents={filtered} />
                  <FilterBox>
                    <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}></Grid>
                    <DisplayModeButtons
                      displayMode={displayMode}
                      handleShowList={() => setDisplayMode('list')}
                      handleShowModule={() => setDisplayMode('module')}
                    />
                  </FilterBox>
                </>
              )}
              <Pagination count={filtered.length} />
            </Content>
          </PaginationProvider>
        </CustomerAgentProvider>
      )}
    </>
  );
};

export default CustomerAgents;
