import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import TopCustomersSales from './TopCustomersSales';
import { useCommercialBi } from '../hooks/useCommercialBi';
import TableLoading from 'components/loading/TableLoading';
import TopItemsSales from './TopItemsSales';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import TopOrdersSales from './TopOrdersSales';
import PaginationProvider from 'hooks/pagination';

interface BlockProps {
  backgroundColor?: string;
}

const Col = styled('div')(props => ({
  display: 'flex',
  flex: 1,
  [props.theme.breakpoints.down('sm')]: {
    display: 'grid',
  },
}));

const Row = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 10,
}));

const Header = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 10,
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  background: '#f5f5f5',
  flex: 1,
  rowGap: 15,
});

const Block = styled('div')<BlockProps>(props => ({
  borderTop: '1px solid #eee',
  borderBottom: '1px solid #eee',
  borderRight: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  '& .title': {
    background: props.backgroundColor ?? 'transparent',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '20px 20px 8px 20px',
  },
  '& .main': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
  '& .quantity': {
    fontWeight: 500,
    fontSize: 12,
  },
}));

const CommercialBiDashboard: React.FC = () => {
  const { sales, stockItems, orders, loading } = useCommercialBi();

  const salesTotal = useMemo(() => {
    return {
      formattedTotal: moneyFormat(sales.reduce((acc, sale) => acc + sale.vl_total, 0)),
      quantity: sales.length,
      formattedMc: moneyFormat(sales.reduce((acc, sale) => acc + sale.mc, 0)),
      formattedAverageTicket: numberFormat(sales.reduce((acc, sale) => acc + sale.vl_total, 0) / sales.length),
      formattedStockValue: moneyFormat(stockItems.reduce((acc, stock) => acc + stock.valor_pedido, 0)),
      formattedOrdersValue: moneyFormat(orders.reduce((acc, order) => acc + order.valor_total, 0)),
      formattedStock: numberFormat(
        stockItems.reduce((acc, stock) => acc + stock.EstTotal, 0),
        0,
      ),
      formattedOrders: numberFormat(
        orders.reduce((acc, order) => acc + order.pedido, 0),
        0,
      ),
    };
  }, [sales, stockItems, orders]);

  return (
    <Container>
      <Header>
        <Col>
          <Block backgroundColor="#f1f7ef">
            <span className="title">Fat bruto</span>
            <div className="main">
              <span className="value">{salesTotal.formattedTotal}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block backgroundColor="#f1f7ef">
            <span className="title">Qtd vendas</span>
            <div className="main">
              <span className="value">{salesTotal.quantity}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>
          <Block backgroundColor="#f1f7ef">
            <span className="title">MC</span>
            <div className="main">
              <span className="value">{salesTotal.formattedMc}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block backgroundColor="#f1f7ef">
            <span className="title">Ticket médio</span>
            <div className="main">
              <span className="value">{salesTotal.formattedAverageTicket}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>
        </Col>

        <Col>
          <Block backgroundColor="#fdf2cd">
            <span className="title">Estoque</span>
            <div className="main">
              <span className="value">{salesTotal.formattedStockValue}</span>
              <span className="quantity">{salesTotal.formattedStock}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>

          <Block backgroundColor="#fdf2cd">
            <span className="title">Pedidos</span>
            <div className="main">
              <span className="value">{salesTotal.formattedOrdersValue}</span>
              <span className="quantity">{salesTotal.formattedOrders}</span>
              <LineAxis color="secondary" />
            </div>
          </Block>
        </Col>
      </Header>

      <Row>
        {loading ? (
          <>
            <TableLoading />
            <TableLoading />
            <TableLoading />
          </>
        ) : (
          <>
            <PaginationProvider>
              <TopCustomersSales />
            </PaginationProvider>

            <PaginationProvider>
              <TopOrdersSales />
            </PaginationProvider>

            <PaginationProvider>
              <TopItemsSales />
            </PaginationProvider>
          </>
        )}
      </Row>
    </Container>
  );
};

export default CommercialBiDashboard;
