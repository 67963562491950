import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import PaginationProvider from 'hooks/pagination';
import { endOfDay, startOfMonth } from 'date-fns';
import FinancialTicketData from './FinancialTicketData';
import { FinancialTicketProvider } from './hooks/useFinancialTicket';
import FinancialTicketFilter from './FinancialTicketFilter';
import { FinancialTicket } from 'types/financialReportList';
import { ticketTableTemplate } from './ticketTableTemplate';
import TicketListTable from './list/table/TicketListTable';
import TicketListModule from './list/module/TicketListModule';
import FinancialTicketMenu from './FinancialTicketMenu';
import FinancialTicketFilterBox from './FinancialTicketFilterBox';
import { useFetchFinancialTicketReport } from './hooks/useFetchFinancialTicketReport';
import SalesTicketReportTotal from './SalesTicketReportTotal';
import FilterActions from 'components/filter-box/FilterActions';
import { useSelector } from 'store/selector';
import { usePermissionRules } from 'hooks/permissionRules';
import FinancialTicketExport from './export/FinancialTicketExport';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import { Print } from '@mui/icons-material';
import FinancialTicketDashboard from './tabs/dashboard/FinancialTicketDashboard';
import FinancialTicketTabs from './FinancialTicketTabs';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { FaFileExcel } from 'react-icons/fa';
import NoData from 'components/no-data/NoData';
import TableLoading from 'components/loading/TableLoading';
import Loading from 'components/loading/Loading';
import FinancialCustomerTicketsDialog from './dialog/FinancialCustomerTicketsDialog';

let timer: NodeJS.Timeout;

export interface FinancialTicketFilterParams {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
  typeDate: string;
  situation: string;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
const HeaderActions = styled('div')({
  display: 'flex',
  columnGap: '10px',
});

const FinancialTicketReport: React.FC = () => {
  const { handleBack } = useFinancial();
  const [tab, setTab] = useState('dashboard');
  const { windowWidth, isMobile } = useApp();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [filterdTicket, setFilteredTicket] = useState<FinancialTicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<FinancialTicket | null>(null);
  const [excelExport, setExcelExport] = useState(false);
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const [filter, setFilter] = useState<FinancialTicketFilterParams>({
    initialDate: startOfMonth(new Date()),
    finalDate: endOfDay(new Date()),
    branchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    situation: 'T',
    typeDate: 'V',
  });
  const {
    handleSearch,
    tickets,
    loading,
    agroupedBranchesTicket,
    agroupedCustomersTicket,
    loadingCustomerInfo,
    handleClickCnpjField,
    customerTickets,
    onCloseCustomerTickets,
  } = useFetchFinancialTicketReport(filter);
  const { checkPermission } = usePermissionRules();

  const selectedBranch = useMemo(() => {
    const branch = branches.find(branch => branch.id === filter.branchId);

    if (!branch) {
      return null;
    }

    return branch;
  }, [filter, branches]);

  useEffect(() => {
    setFilteredTicket(tickets);
  }, [tickets]);

  function handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const filtered = sort(index, filterdTicket);
    setFilteredTicket(filtered);
  }

  function handleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSearch(searchText);
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);

    clearTimeout(timer);

    if (!value.length) return;

    timer = setTimeout(() => handleSearch(value), 800);
  }

  return (
    <FinancialTicketProvider
      value={{
        handleClickCnpjField,
        loading,
        agroupedBranchesTicket,
        agroupedCustomersTicket,
        selectedTicket,
        setSelectedTicket,
        filter,
        handleChangeFilter,
      }}
    >
      {loadingCustomerInfo && <Loading />}
      {customerTickets.length > 0 && (
        <FinancialCustomerTicketsDialog onExited={onCloseCustomerTickets} tickets={customerTickets} />
      )}
      {dialogDate && <FinancialTicketFilter onExited={() => setDialogDate(false)} />}

      {shownReport && (
        <FinancialTicketData data={tickets} branch={selectedBranch} onExited={() => setShownReport(false)} />
      )}

      {excelExport && <FinancialTicketExport onExited={() => setExcelExport(false)} data={tickets} />}

      <Appbar
        backAction={handleBack}
        title="Boletos"
        ActionsComponent={
          <>
            <IconButton color="inherit" onClick={() => setShownReport(true)}>
              <Print />
            </IconButton>
            {windowWidth < 600 && isMobile && <FilterActions handleOpenDialog={() => setDialogDate(true)} />}
            <HeaderActions>
              {checkPermission('general.excel_export_button') && (
                <Tooltip title="Exportar excel">
                  <IconButton color="inherit" disabled={tickets.length === 0} onClick={() => setExcelExport(true)}>
                    <FaFileExcel />
                  </IconButton>
                </Tooltip>
              )}
            </HeaderActions>
          </>
        }
      />

      <FinancialTicketTabs handleChange={value => setTab(value)} value={tab} />

      <FinancialTicketFilterBox
        filter={filter}
        displayMode={displayMode}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        setDisplayMode={setDisplayMode}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      {tab === 'dashboard' ? (
        <>
          <SalesTicketReportTotal groupedBranchesTickets={agroupedBranchesTicket} loading={loading} />
          <FinancialTicketDashboard />
        </>
      ) : (
        <TableContainer tableTemplate={ticketTableTemplate}>
          <PaginationProvider>
            {loading ? (
              <TableLoading />
            ) : filterdTicket.length > 0 ? (
              <Container>
                <FinancialTicketMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                {displayMode === 'list' ? (
                  <TicketListTable
                    setAnchorEl={setAnchorEl}
                    tickets={filterdTicket}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <TicketListModule setAnchorEl={setAnchorEl} tickets={filterdTicket} />
                )}
                <Pagination count={filterdTicket.length} />
              </Container>
            ) : (
              <NoData message="Conteúdo indisponível" />
            )}
          </PaginationProvider>
        </TableContainer>
      )}
    </FinancialTicketProvider>
  );
};

export default FinancialTicketReport;
