import { Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Close, Search } from '@mui/icons-material';
import { FilterOption, FilterSidebar, useSidebarFilters } from './hooks/useSidebarFilters';

type SidebarProps = {
  isOpen: boolean;
};

const Container = styled('div')<SidebarProps>(props => ({
  padding: '0 15px',
  opacity: props.isOpen ? '1' : '0',
  '& .options': {
    overflow: 'auto',
    maxHeight: 225,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15,
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)({
  '& > span': {
    padding: 2,
  },
});

interface SidebarFilterProps {
  filter: FilterSidebar;
  handleClick(value: FilterOption, checked: boolean, param: string): void;
  map: Map<string, string[]>;
}

const SidebarFilter: React.FC<SidebarFilterProps> = ({ filter, handleClick, map }) => {
  const { isSidebarOpen } = useSidebarFilters();
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedIds, setSelectedIds] = useState<string[]>(() => {
    const value = map.get(filter.parametro);

    if (value) {
      return value.map(item => `${item}`);
    }

    return [];
  });

  useEffect(() => {
    setOptions(filter.opcoes);
  }, [filter]);

  function handleSearch(value: string) {
    setSearchText(value);

    const _options = filter.opcoes.filter(item => {
      const searched = item.nome
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return searched.indexOf(value.toLowerCase()) !== -1;
    });

    setOptions(_options);
  }

  function handleCheckBoxClick(value: FilterOption, checked: boolean) {
    handleClick(value, checked, filter.parametro);

    setSelectedIds(state => {
      const id = state.find(id => id === value.id);

      if (id) {
        return state.filter(id => id !== value.id);
      }

      return [...state, `${value.id}`];
    });
  }

  function isChecked(id: string) {
    return selectedIds.some(item => item === id);
  }

  function handleClearInputText() {
    setSearchText('');
    setOptions(filter.opcoes);
  }

  return (
    <Container isOpen={isSidebarOpen} style={{ transition: 'opacity 0.2s linear' }}>
      <div>
        <Typography variant="subtitle2" fontWeight={600}>
          {filter.titulo}
        </Typography>
        {filter.opcoes.length > 25 && (
          <div className="search-box">
            <TextField
              variant="standard"
              placeholder="procurar..."
              margin="normal"
              fullWidth
              onChange={event => handleSearch(event.target.value)}
              value={searchText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchText ? (
                  <IconButton onClick={handleClearInputText} size="small">
                    <Close fontSize="small" />
                  </IconButton>
                ) : undefined,
              }}
            />
          </div>
        )}
        <div className="options">
          {options.map(value => (
            <CustomFormControlLabel
              key={value.id}
              control={
                <Checkbox
                  onChange={event => handleCheckBoxClick(value, event.target.checked)}
                  size="small"
                  color="primary"
                  checked={isChecked(`${value.id}`)}
                />
              }
              label={value.nome}
              slotProps={{
                typography: {
                  variant: 'body2',
                  fontSize: 12,
                },
              }}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SidebarFilter;
