import React from 'react';
import { styled, Typography } from '@mui/material';
import { ProductCharacteristic } from 'types/product';

interface CommercialAnalysisProductHeaderProps {
  product: ProductCharacteristic;
}

const Container = styled('div')({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
});

const Box = styled('div')({
  display: 'flex',
  backgroundColor: '#fff',
  padding: '5px 10px',
  flexDirection: 'column',
  gap: 10,
  boxShadow: '1px 1px 9px 1px #eee',
  border: '1px solid #eee',
});

const CommercialAnalysisProductHeader: React.FC<CommercialAnalysisProductHeaderProps> = ({ product }) => {
  return (
    <Container>
      <Box>
        <Typography variant="body2" fontSize={18}>
          Custo
        </Typography>
        <Typography variant="body2" fontWeight={600} fontSize={16}>
          {product.formattedCusto}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" fontSize={18}>
          Margem
        </Typography>
        <Typography variant="body2" fontWeight={600} fontSize={16}>
          {product.formattedMargem}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" fontSize={18}>
          P2PJ
        </Typography>
        <Typography variant="body2" fontWeight={600} fontSize={16}>
          {product.formattedP2PJ}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" fontSize={18}>
          ICMS Cred
        </Typography>
        <Typography variant="body2" fontWeight={600} fontSize={16}>
          {product.formattedICMSCred}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" fontSize={18}>
          ICMS Deb
        </Typography>
        <Typography variant="body2" fontWeight={600} fontSize={16}>
          {product.formattedICMSDeb}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" fontSize={18}>
          PMD
        </Typography>
        <Typography variant="body2" fontWeight={600} fontSize={16}>
          {product.formattedPmd}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" fontSize={18}>
          PP
        </Typography>
        <Typography variant="body2" fontWeight={600} fontSize={16}>
          {product.formattedPp}
        </Typography>
      </Box>
    </Container>
  );
};

export default CommercialAnalysisProductHeader;
