import React from 'react';
import {
  styled,
  Table,
  TableBody,
  TableCell as OriginalTableCell,
  TableContainer,
  TableHead,
  TableRow as OriginalTableRow,
  Paper,
} from '@mui/material';
import { ProductCharacteristic } from 'types/product';

interface CommercialAnalysisProductReportsProps {
  product: ProductCharacteristic;
}

const TableCell = styled(OriginalTableCell)({
  padding: 5,
  '&.head': {
    color: '#30519f!important',
    fontSize: 14,
    position: 'sticky',
    backgroundColor: '#fff',
    left: 0,
    top: 0,
    bottom: 0,
    fontWeight: 600,
    minWidth: 120,
  },
  minWidth: 90,
  fontSize: 12,
});

const TableRow = styled(OriginalTableRow)({
  position: 'relative',
});

const CommercialAnalysisProductReports: React.FC<CommercialAnalysisProductReportsProps> = ({ product }) => {
  const months = [
    'jan/24',
    'fev/24',
    'mar/24',
    'abr/24',
    'mai/24',
    'jun/24',
    'jul/24',
    'ago/24',
    'set/24',
    'out/24',
    'nov/24',
    'dez/24',
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" className="head">
              Mês
            </TableCell>
            {months.map(month => (
              <TableCell key={month}>{month}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant="head" className="head">
              Valor
            </TableCell>
            <TableCell>R$ 200,00</TableCell>
            <TableCell>R$ 300,00</TableCell>
            <TableCell>R$ 400,00</TableCell>
            <TableCell>R$ 500,00</TableCell>
            <TableCell>R$ 600,00</TableCell>
            <TableCell>R$ 700,00</TableCell>
            <TableCell>R$ 800,00</TableCell>
            <TableCell>R$ 900,00</TableCell>
            <TableCell>R$ 1000,00</TableCell>
            <TableCell>R$ 1100,00</TableCell>
            <TableCell>R$ 1200,00</TableCell>
            <TableCell>R$ 1300,00</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" className="head">
              MC
            </TableCell>
            <TableCell>R$ 200,00</TableCell>
            <TableCell>R$ 300,00</TableCell>
            <TableCell>R$ 400,00</TableCell>
            <TableCell>R$ 500,00</TableCell>
            <TableCell>R$ 600,00</TableCell>
            <TableCell>R$ 700,00</TableCell>
            <TableCell>R$ 800,00</TableCell>
            <TableCell>R$ 900,00</TableCell>
            <TableCell>R$ 1000,00</TableCell>
            <TableCell>R$ 1100,00</TableCell>
            <TableCell>R$ 1200,00</TableCell>
            <TableCell>R$ 1300,00</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" className="head">
              QTD Vendida
            </TableCell>
            <TableCell>2</TableCell>
            <TableCell>3</TableCell>
            <TableCell>4</TableCell>
            <TableCell>5</TableCell>
            <TableCell>6</TableCell>
            <TableCell>7</TableCell>
            <TableCell>8</TableCell>
            <TableCell>9</TableCell>
            <TableCell>10</TableCell>
            <TableCell>11</TableCell>
            <TableCell>12</TableCell>
            <TableCell>13</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommercialAnalysisProductReports;
