import { format, subDays } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useState } from 'react';
import { useSelector } from 'store/selector';
import { NoteEntry } from 'types/noteEntry';

export interface FilterNoteEntry {
  initial_date: Date;
  final_date: Date;
  branch_id: number;
  product: string;
  customer: string;
}

export function useFetchNoteEntry() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<NoteEntry[]>([]);
  const [filter, setFilter] = useState<FilterNoteEntry>({
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    final_date: new Date(),
    initial_date: subDays(new Date(), 7),
    customer: '',
    product: '',
  });

  function handleChangeFilter(index: keyof FilterNoteEntry, value: any) {
    setFilter(item => ({
      ...item,
      [index]: value,
    }));
  }

  const onSearch = useCallback(
    (filter: FilterNoteEntry) => {
      if (!h2iApi) return;
      if (!user?.branchList) return;

      setLoading(true);

      const formattedInitialDate = format(filter.initial_date, "dd'.'MM'.'yyyy");
      const formattedFinalDate = format(filter.final_date, "dd'.'MM'.'yyyy");
      const productId = parseInt(filter.product);

      h2iApi
        .get('/api/getrelentradanf', {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            produto: isNaN(productId)
              ? filter.product
              : filter.product.length === productId.toString().length
              ? ''
              : filter.product,
            id_produto: isNaN(productId) ? '' : filter.product.length === productId.toString().length ? productId : '',
            id_filial: filter.branch_id || '',
            cliente: filter.customer,
          },
        })
        .then(response => {
          const _notes = response.data.map((item: NoteEntry) => {
            item.formattedAddition = moneyFormat(item.valor_acrescimo);
            item.formattedTotal = moneyFormat(item.valor_total);
            item.formattedIpi = moneyFormat(item.valor_ipi);
            item.formattedDiscount = moneyFormat(item.valor_desconto);
            item.formattedShipment = moneyFormat(item.valor_desconto);

            return item;
          });

          setNotes(_notes);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, user],
  );

  return {
    loading,
    notes,
    filter,
    handleChangeFilter,
    onSearch,
  };
}
