import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Button, TextField, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { CommercialAnalysisProductFilterParams } from './hooks/useFetchCommercialAnalysisProduct';
import { Search } from '@mui/icons-material';

const Filter = styled('form')(({ theme }) => ({
  gap: 10,
  flex: 1,
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '300px 200px 100px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '200px 200px 100px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 100px',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 15,
    gridTemplateColumns: '1fr',
  },
}));

interface CommercialAnalysisProductFilterBoxProps {
  filter: CommercialAnalysisProductFilterParams;
  handleChangeFilter(index: keyof CommercialAnalysisProductFilterParams, value: any): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
}

const CommercialAnalysisProductFilterBox: React.FC<CommercialAnalysisProductFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  handleSearchSubmit,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <TextField
          label="Código do produto"
          placeholder="Digite o ID do produto"
          autoFocus
          inputMode="numeric"
          type="number"
          value={filter.productId}
          onChange={e => handleChangeFilter('productId', e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default CommercialAnalysisProductFilterBox;
